<div>
    <div class="manual-header">
        <h4 class="modal-title" id="myModalLabel">{{headerTitle}}</h4>
        <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
            <svg-icon name="modal-close-icon" class="icon"></svg-icon>
        </button>
    </div>
    <div class="modal-body">
        <div class="confirm-message">
            <div class="confirm-message-content" [innerText]="message"></div>
        </div>
    </div>
</div>