export enum MenuItemLabels {
  Clients = 'Clients',
  Matters = 'Matters',
  Timesheets = 'Timesheets',
  Billing = 'Billing',
  BillOnCompletion = 'Bill On Completion',
  City = 'City',
  Enterprise = 'Enterprise',
  ExtendedEnterprise = 'Extended Enterprise',
  BillingWip = 'Billing Wip',
  BillingRuns = 'Billing Runs',
  Disbursement = 'Disbursement',
  TimesheetReport = 'Timesheet Report',
  BusinessReporting = 'Business Reporting',
  WorkRequests = 'Work Requests',
  Users = 'Users',
  Settings = 'Settings',
  Configuration = 'Configuration',
  Roles = 'Roles',
  SystemConfiguration = 'System Configuration',
  Feedback = 'Feedback',
  Logout = 'Logout',
  CompleteNewMatter = 'Complete New Matter',
  Feedbacks = 'Feedbacks',
  HjHours = 'Hjhours',
  TempTimeHour = 'Temptimehour',
  BillableHour = 'BillableHour',
  EditProfile = 'editprofile',
  AiChat = 'AI Chat',
  ClientRates = 'Client Rates',
}

export enum ClientMenuItemLabels {
  Instructions = 'Instructions',
  Matters = 'Matters',
  Invoices = 'Invoices',
  Users = 'Users',
  Logout = 'Logout',
  EditProfile = 'Edit-Profile',
}
